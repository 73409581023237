/*********
 * Fonts *
 *********/

@font-face {
    font-family: 'Montserrat';
    src: url('../../resources/fonts/Montserrat/Montserrat-Regular-webfont.woff2') format('woff2'), url('../../resources/fonts/Montserrat/Montserrat-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../resources/fonts/Open Sans/OpenSans-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../resources/fonts/Open Sans/OpenSans-Light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

/*****************
 * Design Tokens *
 *****************/

:root {
    /** Colours **/

    --hex-antique-white: #faebd7;
    --hex-black: #000000;
    --hex-blue-lagoon: #006275;
    --hex-caravan-violet: #230639;
    --hex-charcoal: #292c30;
    --hex-cloudy-grey: #dbdbdb;
    --hex-cloudy-grey-dark: #999999;
    --hex-coral-blue: #137d96;
    --hex-dark-glacier: #01377f;
    --hex-error-red: #f93d5c;
    --hex-forest-green: #1d6a4d;
    --hex-icy-blue: #0077bb;
    --hex-jewel-green: #629b53;
    --hex-light-grey: #f4f4f4;
    --hex-margetts-purple: #4a2683;
    --hex-maroon: #681528;
    --hex-mountain-grey: #575d68;
    --hex-red-violet: #8e3154;
    --hex-smoke-grey: #c9c7c7;
    --hex-success-green: #01a982;
    --hex-sunrise-yellow: #ffaa49;
    --hex-sunset-orange: #f57a3e;
    --hex-warning-orange: #f57a3e;
    --hex-white: #ffffff;

    --gradient-degrees-light-to-dark-horizontal: 90deg;
    --gradient-degrees-light-to-dark-vertical: 180deg;
    --gradient-degrees-dark-to-light-hotizontal: 270deg;
    --gradient-degrees-dark-to-light-vertical: 0deg;

    --gradient-coral-blue: linear-gradient(var(--gradient-degrees-light-to-dark-horizontal), var(--hex-coral-blue) 0%, var(--hex-blue-lagoon) 100%);
    --gradient-icy-blue: linear-gradient(var(--gradient-degrees-light-to-dark-horizontal), var(--hex-icy-blue) 0%, var(--hex-dark-glacier) 100%);
    --gradient-jewel-green: linear-gradient(var(--gradient-degrees-light-to-dark-horizontal), var(--hex-jewel-green) 0%, var(--hex-forest-green) 100%);
    --gradient-margetts-purple: linear-gradient(var(--gradient-degrees-light-to-dark-horizontal), var(--hex-margetts-purple) 0%, var(--hex-caravan-violet) 100%);
    --gradient-mountain-grey: linear-gradient(var(--gradient-degrees-light-to-dark-horizontal), var(--hex-mountain-grey) 0%, var(--hex-charcoal) 100%);
    --gradient-red-violet: linear-gradient(var(--gradient-degrees-light-to-dark-horizontal), var(--hex-red-violet) 0%, var(--hex-maroon) 100%);
    --gradient-sunrise-yellow: linear-gradient(var(--gradient-degrees-light-to-dark-horizontal), var(--hex-sunrise-yellow) 0%, var(--hex-sunset-orange) 100%);

    --gradient-coral-blue-vertical: linear-gradient(var(--gradient-degrees-light-to-dark-vertical), var(--hex-coral-blue) 0%, var(--hex-blue-lagoon) 100%);
    --gradient-icy-blue-vertical: linear-gradient(var(--gradient-degrees-light-to-dark-vertical), var(--hex-icy-blue) 0%, var(--hex-dark-glacier) 100%);
    --gradient-jewel-green-vertical: linear-gradient(var(--gradient-degrees-light-to-dark-vertical), var(--hex-jewel-green) 0%, var(--hex-forest-green) 100%);
    --gradient-margetts-purple-vertical: linear-gradient(var(--gradient-degrees-light-to-dark-vertical), var(--hex-margetts-purple) 0%, var(--hex-caravan-violet) 100%);
    --gradient-mountain-grey-vertical: linear-gradient(var(--gradient-degrees-light-to-dark-vertical), var(--hex-mountain-grey) 0%, var(--hex-charcoal) 100%);
    --gradient-red-violet-vertical: linear-gradient(var(--gradient-degrees-light-to-dark-vertical), var(--hex-red-violet) 0%, var(--hex-maroon) 100%);
    --gradient-sunrise-yellow-vertical: linear-gradient(var(--gradient-degrees-light-to-dark-vertical), var(--hex-sunrise-yellow) 0%, var(--hex-sunset-orange) 100%);

    --colour-black: var(--hex-black);
    --colour-dark-grey: var(--hex-mountain-grey);
    --colour-light-grey: var(--hex-light-grey);
    --colour-white: var(--hex-white);

    --colour-success: var(--hex-success-green);
    --colour-warning: var(--hex-warning-orange);
    --colour-error: var(--hex-error-red);

    --opacity-tint: 0.7;
    --colour-opaque-background: rgba(0, 0, 0, var(--opacity-tint));

    /** Typography **/

    --font-family-default: 'Open Sans', sans-serif;
    --font-family-headings: 'Montserrat', Arial, serif;
    --font-family-code: monospace;

    --font-size-baseline: 14px;

    /** Iconography **/

    --font-family-icons: 'Font Awesome 5 Pro';

    /** Imagery **/


    /** Motion **/

    --timing-animation: 0.3s;
    --timing-hover-easing: 0.1s;

    /** Sizing **/

    --container-width: 768px;

    --border-radius: 2px;
    --border-width: 1px;

    --header-height: 72px;
    --header-height-compact: 46px;
    --header-border: 2px;

    /** Media queries **/

    --media-medium: 768px;
    --media-large: 1024px;
    --media-extra-large: 1200px;
}

@media screen and (min-width: 1024px) {
    :root {
        --font-size-baseline: 16px;
        --header-height: 82px;
    }
}

@media screen and (min-width: 1200px) {
    :root {
        --container-width: 1152px;
    }
}

@media screen and (min-width: 1440px) {
    :root {
        --container-width: 1200px;
    }
}

/************
 * Baseline *
 ************/

/** Baseline - Fixes **/

[hidden] {
    display: none !important;
}

/** Baseline - Defaults */

html {
    background-color: var(--colour-white);
    color: var(--hex-charcoal);
    font-family: var(--font-family-default);
    font-size: var(--font-size-baseline);
}

html,
body,
#app {
    margin: 0;
    min-height: 100vh;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.show-on-medium,
.show-on-large,
.navigation ul.show-on-large,
.show-on-extra-large {
    display: none;
}

@media screen and (min-width: 768px) {
    .show-on-medium {
        display: initial;
    }

    .hide-on-medium {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .show-on-large,
    .navigation ul.show-on-large {
        display: initial;
    }

    .hide-on-large {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .show-on-extra-large {
        display: initial;
    }

    .hide-on-extra-large {
        display: none;
    }
}

@media (display-mode: standalone) {
    .hide-in-app {
        display: none !important;
    }
}

@media (display-mode: minimal-ui) {
    .hide-in-app {
        display: none !important;
    }
}

/**********
 * Styles *
 **********/

/** Styles - Colour **/

.background-antique-white {
    background-color: var(--hex-antique-white);
}

.background-black {
    background-color: var(--hex-black);
}

.background-blue-lagoon {
    background-color: var(--hex-blue-lagoon);
}

.background-caravan-violet {
    background-color: var(--hex-caravan-violet);
}

.background-charcoal {
    background-color: var(--hex-charcoal);
}

.background-cloudy-grey {
    background-color: var(--hex-cloudy-grey);
}

.background-coral-blue {
    background-color: var(--hex-coral-blue);
}

.background-dark-glacier {
    background-color: var(--hex-dark-glacier);
}

.background-error-red {
    background-color: var(--colour-error);
}

.background-forest-green {
    background-color: var(--hex-forest-green);
}

.background-icy-blue {
    background-color: var(--hex-icy-blue);
}

.background-jewel-green {
    background-color: var(--hex-jewel-green);
}

.background-light-grey {
    background-color: var(--hex-light-grey);
}

.background-margetts-purple {
    background-color: var(--hex-margetts-purple);
}

.background-maroon {
    background-color: var(--hex-maroon);
}

.background-mountain-grey {
    background-color: var(--hex-mountain-grey);
}

.background-red-violet {
    background-color: var(--hex-red-violet);
}

.background-colour-success {
    background-color: var(--colour-success);
}

.background-sunrise-yellow {
    background-color: var(--hex-sunrise-yellow);
}

.background-sunset-orange {
    background-color: var(--hex-sunset-orange);
}

.gradient-coral-blue {
    background: var(--gradient-coral-blue);
}

.gradient-coral-blue.gradient-vertical {
    background: var(--gradient-coral-blue-vertical);
}

.gradient-icy-blue {
    background: var(--gradient-icy-blue);
}

.gradient-icy-blue.gradient-vertical {
    background: var(--gradient-icy-blue-vertical);
}

.gradient-jewel-green {
    background: var(--gradient-jewel-green);
}

.gradient-jewel-green.gradient-vertical {
    background: var(--gradient-jewel-green-vertical);
}

.gradient-margetts-purple {
    background: var(--gradient-margetts-purple);
}

.gradient-margetts-purple.gradient-vertical {
    background: var(--gradient-margetts-purple-vertical);
}

.gradient-mountain-grey {
    background: var(--gradient-mountain-grey);
}

.gradient-mountain-grey.gradient-vertical {
    background: var(--gradient-mountain-grey-vertical);
}

.gradient-red-violet {
    background: var(--gradient-red-violet);
}

.gradient-red-violet.gradient-vertical {
    background: var(--gradient-red-violet-vertical);
}

.gradient-sunrise-yellow {
    background: var(--gradient-sunrise-yellow);
}

.gradient-sunrise-yellow.gradient-vertical {
    background: var(--gradient-sunrise-yellow-vertical);
}

.colour-antique-white {
    color: var(--hex-antique-white);
}

.colour-black {
    color: var(--hex-black);
}

.colour-blue-lagoon {
    color: var(--hex-blue-lagoon);
}

.colour-caravan-violet {
    color: var(--hex-caravan-violet);
}

.colour-charcoal {
    color: var(--hex-charcoal);
}

.colour-cloudy-grey {
    color: var(--hex-cloudy-grey);
}

.colour-coral-blue {
    color: var(--hex-coral-blue);
}

.colour-dark-glacier {
    color: var(--hex-dark-glacier);
}

.colour-error-red {
    color: var(--colour-error);
}

.colour-forest-green {
    color: var(--hex-forest-green);
}

.colour-icy-blue {
    color: var(--hex-icy-blue);
}

.colour-jewel-green {
    color: var(--hex-jewel-green);
}

.colour-light-grey {
    color: var(--hex-light-grey);
}

.colour-margetts-purple {
    color: var(--hex-margetts-purple);
}

.colour-maroon {
    color: var(--hex-maroon);
}

.colour-mountain-grey {
    color: var(--hex-mountain-grey);
}

.colour-red-violet {
    color: var(--hex-red-violet);
}

.colour-success {
    color: var(--colour-success);
}

.colour-sunrise-yellow {
    color: var(--hex-sunrise-yellow);
}

.colour-sunset-orange {
    color: var(--hex-sunset-orange);
}

/** Styles - Typography */

a,
.link {
    padding: 0;
    background-color: transparent;
    color: var(--colour-primary);
    outline: 0;
    transition: all var(--timing-hover-easing) ease-out
}

a:active,
a:hover,
a:focus,
.link:hover,
.link:focus {
    color: var(--colour-primary-shadow);
}

a:active,
a:focus,
.link:focus {
    outline: var(--border-width) dotted var(--colour-dark-grey);
}

.link {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font: inherit;
}

code {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    margin: 0 0.2em;
    background-color: var(--colour-light-grey);
    font-family: var(--font-family-code);
    font-size: 0.8em;
}

.code-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2rem;
    line-height: 2rem;
}

.code-block > button {
    margin: 1rem;
}

hr {
    border: var(--border-width) solid var(--colour-dark-grey);
}

p {
    margin: 1em 0 0;
    line-height: 1.8em;
}

p + p {
    margin: 1em 0;
}

p.tip {
    font-size: 0.9em;
}

pre {
    display: inline-block;
    padding: 1rem;
    border: var(--border-width) solid var(--colour-light-grey);
    margin: 0;
}

/** Styles - Iconography **/

.fa,
.fab,
.fad,
.fa-light,
.far,
.fas {
    width: 2rem;
    min-height: 1.2em;
    text-align: center;
    vertical-align: middle;
}

.fa-check {
    color: var(--colour-success);
}

.fa-times {
    color: var(--colour-error);
}

/** Styles - Imagery */

img,
picture {
    max-width: 100%;
}

picture > img {
    max-height: 100%;
}

a > img:hover {
    opacity: var(--opacity-tint);
}

img.icon,
img.logo,
img.thumbnail,
img.small,
img.medium,
img.large {
    vertical-align: middle;
}

img.icon,
picture.icon > img {
    max-height: 32px;
    max-width: 32px;
}

img.logo,
picture.logo > img {
    max-height: var(--header-height);
}

img.thumbnail,
picture.thumbnail > img {
    max-height: 200px;
    max-width: 200px;
}

img.small,
picture.small > img {
    max-height: 400px;
    max-width: 400px;
}

img.medium,
picture.medium > img {
    max-height: 800px;
    max-width: 800px;
}

img.large,
picture.large > img {
    max-height: 1200px;
    max-width: 1200px;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: var(--header-height-compact);
    max-height: var(--header-height-compact);
    padding: 1rem;
}

.logo > a {
    display: inline-block;
}

.logo-regular {
    display: none;
}

.logo img {
    max-height: var(--header-height-compact);
}

@media screen and (min-width: 1350px) {
    .logo {
        width: auto;
        max-height: unset;
    }

    .logo img {
        max-height: calc(var(--header-height) - 1rem);
    }

    .logo-compact {
        display: none;
    }

    .logo-regular {
        display: inline;;
    }
}

/**************
 * Components *
 **************/

/** Components - Blocks and cards */

.card {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    padding: 1em;
    border: var(--border-width) solid var(--colour-light-grey);
}

.card h2,
.card h3 {
    margin: 0;
}

/** Components - Form controls */

a.button,
button.button,
label.button,
.tox button.tox-button {
    padding: 0.5rem 2rem;
    border: 0;
    border-radius: 2px;
    margin: 0;
    background-color: var(--colour-black);
    color: var(--colour-white);
    cursor: pointer;
    box-shadow: none;
    font-family: var(--font-family-default);
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    transition: all var(--timing-hover-easing) ease-out;
    white-space: nowrap;
}

.button:focus,
.button:hover,
.tox button.tox-button:focus,
.tox button.tox-button:hover:not(:disabled),
.tox button.tox-button--naked.tox-button--icon:hover:not(:disabled) {
    background-color: var(--colour-black);
    color: var(--colour-white);
}

button:focus,
.button:focus,
input:focus,
select:focus,
section:focus {
    outline: dotted var(--border-width) var(--colour-dark-grey);
}

p > button,
p > .button {
    margin: 1em 0 0;
}

@supports (color: color-contrast()) {
    .button.button-primary {
        color: color-contrast(var(--colour-primary) vs var(--colour-white), var(--colour-black));
    }
}

.button.button-primary:not(:hover):not(:focus) {
    background-color: var(--colour-primary);
    color: var(--colour-white);
}

.navigation-content .button.button-primary:not(:hover):not(:focus) {
    background-color: var(--colour-black);
}

@media screen and (min-width: 1024px) {
    .navigation-content .button.button-primary:not(:hover):not(:focus) {
        background-color: var(--colour-primary);
    }
}

.button.button-transparent {
    background: transparent;
    color: var(--colour-primary);
}

.button.button-large {
    display: inline-block;
    padding: 1.5em 4em;
}

.button.button-wide {
    width: 100%;
    padding: 0.5rem 0;
}

.button.button-large.button-wide {
    padding: 1.5em 2rem;
}

/** Components - Headers and footers */

.font-heading {
    font-family: var(--font-family-headings);
}

h1,
h2,
h3,
h4,
h5 {
    margin: 3rem 0 1em;
    line-height: 1;
    font-weight: normal;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover {
    opacity: var(--opacity-tint);
}

h1 {
    margin: 1em 0 0.7rem 0;
    font-size: 2.5rem;
    text-align: center;
}

@media screen and (min-width: 768px) {
    h1 {
        font-size: 3rem;
        text-align: inherit;
    }
}

h2 {
    color: var(--colour-primary);
    font-size: 1.5rem;
}

h3 {
    font-size: 1.5rem;
    font-weight: bold;
}

h4 {
    font-size: 1.2rem;
}

.uppercase-paragraph,
h5 {
    margin: 0;
    color: var(--colour-dark-grey);
    font-family: var(--font-family-headings);
    font-size: 0.8rem;
    text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    .page-component:first-child,
    .page-widget:first-child {
        margin-top: 0;
    }
}

/** Components - Interactive panels */

.tabs-container {
    margin-bottom: 2em;
}

.tabs {
    display: flex;
    border-bottom: var(--border-width) solid var(--colour-dark-grey);
}

.tab > a {
    display: block;
    padding: 0.5em 2em;
    margin-bottom: calc(var(--border-width) * -1);
    min-width: 5rem;
    text-align: center;
}

.tab > a.is-active {
    border: var(--border-width) solid var(--colour-dark-grey);
    border-bottom: var(--border-width) solid var(--colour-white);
}

.tab-panel {
    display: none;
    padding: 1em;
}

.tab-panel.is-active {
    display: block
}

/** Components - Lists and collections */

ul a,
ol a,
li a {
    text-decoration: none;
}

.content-area ul a,
.content-area ol a,
.content-area li a {
    text-decoration: underline;
}

.card-list {
    display: flex;
    flex-direction: column;
}

@supports (display: grid) {
    .card-list {
        //display: grid;
        grid-gap: 1em;
        grid-template-columns: repeat(auto-fill, minmax(calc(33% - 0.5em), 1fr));
    }
}

.card-list > div {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.card-list > li {
    width: 100%;
}

.card-list .button {
    max-width: 75%;
    margin: 1em auto;
}

@media screen and (min-width: 1024px) {
    .card-list {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
    }

    .card-list > div {
        flex: 1;
    }

    .card-list > div picture {
        display: flex;
    }
}

/** Components - Messaging */

.notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    margin: 1em 0;
    border-radius: 2px;
    background-color: var(--colour-primary);
    color: var(--colour-white);
}

.notification a {
    color: var(--colour-white);
    margin-left: 1em;
}

.notification-flash {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1207;
    padding: 1em;
    margin: 2em;
}

.notification-flash.success {
    background-color: var(--colour-success);
    color: var(--colour-white);
}

.notification-flash.warning {
    background-color: var(--colour-warning);
    color: var(--colour-white);
}

.notification-flash.error {
    background-color: var(--colour-error);
    color: var(--colour-white);
}

.notification-flash .button {
    padding: 0;
    margin-left: 0.5rem;
    background-color: transparent;
}

.notification-flash .button i {
    margin-top: 4px;
    color: var(--colour-white);
}

.notification-flash .button:focus,
.notification-flash .button:hover {
    background-color: transparent;
}

.notification p {
    margin: 0;
}


.alert {
    padding: 1em;
    margin-bottom: 1em;
    border: solid var(--border-width) var(--colour-primary);
    border-radius: var(--border-radius);
    background-color: var(--colour-primary);
    color: var(--colour-white);
}

.alert-success {
    color: var(--colour-white);
    background-color: var(--colour-success);
    border-color: var(--colour-success);
}

.alert-warning {
    color: var(--colour-white);
    background-color: var(--colour-warning);
    border-color: var(--colour-warning);
}

.alert-error,
.alert-failure {
    color: var(--colour-white);
    background-color: var(--colour-error);
    border-color: var(--colour-error);
}

/** Components - Navigation */

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
    font-size: 0.9em;
}

.breadcrumbs ol {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.breadcrumbs ol > li {
    margin: 1em 0 1em 0.5em;
}

.breadcrumbs ol li:not(:last-of-type):after {
    content: '\00BB';
    margin-left: 0.5em;
}

.breadcrumbs a {
    text-decoration: underline;
}

main .navigation {
    border-radius: var(--border-radius);
}

.navigation .navigation-content {
    display: block;
    position: absolute;
    top: calc(var(--header-height) + var(--header-border));
    right: 0;
    width: 100%;
    max-height: calc(100vh - var(--header-height));
    overflow-y: scroll;
    border-bottom: solid var(--border-width) var(--colour-white);
    background-color: var(--colour-primary);
    color: var(--colour-white);
    text-align: right;
}

.navigation a,
.navigation .link {
    color: var(--colour-white);
}

.navigation ul {
    display: flex;
    flex-direction: column;
    padding: 1em;
    margin: 0;
    list-style: none;
}

.navigation ul:first-of-type {
    border-bottom: var(--border-width) solid transparent;
}

.navigation > ul {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    margin: 0;
}

.navigation ul li {
    padding: 1em;
}

main .navigation ul li:first-of-type {
    padding: 0;
}

.navigation ul ul:first-of-type {
    padding: 0;
    border-bottom: none;
    margin: 0.5em 0 0 0;
    background-color: var(--colour-primary-shadow);
}

header .navigation > button {
    padding: 0.5em;
    margin-top: 0.3em;
    font-size: 2em;
}

header .navigation > ul > li ul {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

header .navigation > ul > li ul > li {
    padding: 0.5em;
}

header .navigation > ul > li ul > li a:not(.button) {
    color: var(--colour-white);
}

header .navigation > ul > li.active > a:not(.button) {
    color: var(--colour-primary);
    font-weight: 600;
}

.navigation .link.js-toggle {
    margin: 0.5em 0;
}

header .navigation > ul > li.active > .megaNav,
header .navigation > ul > li.active > ul {
    display: flex;
}

.navigation a:focus,
.navigation a:hover {
    text-decoration: none;
}

.navigation svg:not(.logo) {
    margin-left: 0.5em;
}

header .navigation > ul > li > .megaNav,
header .navigation > ul > li > ul {
    display: none;
    position: absolute;
    left: 0;
    width: 70%;
    padding: 1em 15%;
    margin-top: 2em;
    background-color: var(--colour-primary);
    color: var(--colour-white);
}

header .navigation > ul > li > .megaNav > ul {
    display: flex;
    padding: 0;
}

.megaNav > ul:nth-of-type(2) {
    display: none;
    justify-content: flex-start;
}

.megaNav .empty {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.megaNav .empty .fa-light {
    font-size: 2rem;
}

.megaNav .empty .fa-light.fa-bug {
    transform: rotate(30deg);
}

@media screen and (min-width: 1024px) {
    #app > header {
        justify-content: flex-start;
    }

    .navigation {
        width: 100%;
    }

    .navigation .navigation-content,
    .navigation .navigation-content[hidden] {
        display: flex !important;
        justify-content: space-between;
        position: initial;
        top: 0;
        overflow: hidden;
        border-bottom: none;
        background-color: transparent;
        color: var(--colour-black);
        text-align: initial;
    }

    .navigation ul {
        flex-direction: row;
        align-items: center;
    }

    .navigation ul li {
        padding: 0 0.7em;
    }

    .navigation a:not(.button),
    .navigation .link {
        padding: 0.5em 0;
        color: var(--colour-black);
        white-space: nowrap;
    }

    .navigation a:not(.button) span,
    .navigation .link span {
        padding-bottom: 0.5rem;
    }

    .navigation a.button,
    .navigation button.button {
        color: var(--colour-white);
    }

    .navigation a:not(.button):active span,
    .navigation a:not(.button):focus span,
    .navigation a:not(.button):hover span,
    .navigation .link:active span,
    .navigation .link:focus span,
    .navigation .link:hover span {
        border-bottom: solid 2px var(--colour-primary);
    }

    .navigation .link.js-toggle {
        margin: 0;
    }

    .megaNav {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 1em 15%;
        border-bottom: solid var(--border-width) var(--colour-white);
        margin-top: calc(var(--header-height) / 2 - var(--border-width) - 1rem);
        background-color: var(--colour-primary);
        color: var(--colour-white);
    }

    .megaNav a {
        color: var(--colour-white);
    }

    .megaNav a:not(.button),
    .megaNav .link,
    .megaNav a:not(.button):active,
    .megaNav a:not(.button):focus,
    .megaNav a:not(.button):hover {
        color: var(--colour-white);
    }

    .megaNav > ul:nth-of-type(2) {
        display: flex;
        max-width: 50%;
    }

    .navigation ul ul:first-of-type {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-width: 20%;
        margin-top: 0;
        background-color: transparent;
    }

    .navigation ul ul li {
        padding: 0.5em 0;
    }

    .synopsis h3 {
        margin-top: 0;
    }
}

@media screen and (min-width: 1200px) {
    .navigation ul li {
        padding: 0 1em;
    }
}

/****************
 * To Integrate *
 ****************/

/** Baseline - Layout */

#app > main {
    width: 100%;
    margin-top: calc(var(--header-height) + var(--header-border));
    margin-bottom: 1rem;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 auto;
}

.container,
footer {
    width: calc(100% - 4rem);
    padding: 2rem;
    margin: auto 0 0 0;
}

.one-column {
    width: 100%;
    margin: 0.5rem 0 1rem 0;
}

@media screen and (min-width: 1024px) {
    .one-column {
        margin: 0.5rem 0 1rem 0;
    }
}

.two-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem;
}

.two-column > .content-area {
    width: 50%;
    padding: 0 1em;
}

@media screen and (min-width: 1024px) {
    .two-column {
        margin: 1rem 0;
    }
}

.reverse-colour {
    background-color: #000000;
    color: #ffffff;
}

.reverse-colour > a, .reverse-colour > div > a, .reverse-colour > div > p > a  {
    background-color: transparent !important;
    color: #ffffff !important;
    padding-left: 0 !important;
    text-align: left !important;
    text-decoration: underline;
}

.reverse-colour > div {
    align-content: center;
    padding: 1em !important;
}

.two-column-large-font {
    font-size: 3rem;
}

.error-page .logo {
    padding: 0.4em 1em 1em 1em;
}

.error-4xx,
.error-5xx {
    text-align: center;
}

.error-4xx h1,
.error-5xx h1 {
    color: var(--colour-primary);
}

.error-4xx p,
.error-5xx p {
    margin: 1rem 0 1rem;
}

.error-navigation a {
    display: inline-block;
}

/** Components - Headers and footers */

#app > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1205;
    width: 100%;
    height: var(--header-height);
    padding: 0;
    border-bottom: var(--header-border) solid var(--colour-primary);
    background-color: var(--colour-white);
    will-change: contents;
    transition: var(--timing-animation);
}

@media screen and (min-width: 1024px) {
    #app > header.compact {
        height: var(--header-height-compact);
    }

    #app > header.compact .logo .logo-regular {
        display: none;
    }

    #app > header.compact .logo .logo-compact {
        display: block;
        max-width: calc(var(--header-height-compact) - 1rem);
        max-height: calc(var(--header-height-compact) - 1rem);
    }

    #app > header.compact .navigation ul {
        padding: 0 1em;
    }

    #app > header.compact .megaNav {
        margin-top: 3px;
    }
}

/** Components - Form controls */

.form-media,
.form-group {
    margin: 1em 0;
}

.form-group.form-group-toggle {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    .form-group.form-group-toggle:not(.vertical) {
        flex-direction: row;
        align-items: center;
    }
}

.form-group:last-of-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4em;
}

.form-group:last-of-type button[type="submit"],
.form-group:last-of-type a.button {
    margin-right: 1em;
}

.form-group:last-of-type button[type="submit"] + .button {
    margin-left: auto;
}

.form-group input[type="email"],
.form-group input[type="number"],
.form-group input[type="password"],
.form-group input[type="search"]:not(.selectr-tag-input),
.form-group input[type="text"],
.form-group select,
.form-group .selectr-input,
.form-group .selectr-selected,
.form-group textarea {
    display: block;
    min-width: 80%;
    width: calc(100% - 2rem);
    padding: 0.5em;
    border: solid var(--border-width) var(--colour-dark-grey);
    margin: 1em 0;
    border-radius: var(--border-radius);
    font-family: var(--font-family-default);
    font-size: var(--font-size-baseline);
}

.form-group input[type="email"][readonly],
.form-group input[type="number"][readonly],
.form-group input[type="password"][readonly],
.form-group input[type="search"]:not(.selectr-tag-input)[readonly],
.form-group:not(.form-group-date) input[type="text"][readonly],
.form-group select[readonly],
.form-group .selectr-input[readonly],
.form-group .selectr-selected[readonly],
.form-group textarea[readonly] {
    opacity: 0.5;
}

.form-group input[type="email"].warning,
.form-group input[type="number"].warning,
.form-group input[type="password"].warning,
.form-group input[type="search"].warning:not(.selectr-tag-input),
.form-group input[type="text"].warning,
.form-group select.warning,
.form-group .selectr-input.warning,
.form-group .selectr-selected.warning,
.form-group textarea.warning {
    border-color: var(--colour-error);
}

@media screen and (min-width: 768px) {
    .form-group input[type="email"],
    .form-group input[type="number"],
    .form-group input[type="password"],
    .form-group input[type="search"]:not(.selectr-tag-input),
    .form-group input[type="text"],
    .form-group select,
    .form-group .selectr-input,
    .form-group .selectr-selected,
    .form-group textarea {
        width: auto;
    }
}

.form-group input[type="file"] {
    position: absolute;
    opacity: 0;
}

.form-group input[type="file"]:focus + label {
    outline: dotted var(--border-width) var(--colour-black);
}

.form-group textarea {
    min-height: 300px;
}

.form-group .button {
    font-family: var(--font-family-default);
    font-size: 1em;
}

.form-group .invalid-feedback {
    color: var(--colour-error);
}

.form-group .toggle {
    position: absolute;
    top: 85%;
    z-index: 1;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    opacity: 0;
}

.form-group .toggle:focus {
    outline: none;
}

.form-group .toggle + label {
    display: block;
    position: relative;
    width: 4em;
    height: 2em;
    border: solid var(--border-width) var(--colour-primary);
    margin: 2em 0;
    background-color: var(--colour-dark-grey);
    cursor: pointer;
    border-radius: 9999px;
}

.table .form-group .toggle + label,
.toggle-selector .toggle + label {
    margin: 0.175em 0;
}

.form-group-toggle + .form-group-toggle .toggle + label {
    margin-top: 0;
}

.form-group .toggle:focus + label {
    outline: dotted var(--border-width) var(--colour-primary);
}

.form-group .toggle:disabled + label {
    opacity: 0.5;
}

.form-group .toggle + label > div {
    position: absolute;
    left: 5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 60vw;
    max-width: 60vw;
}

@media screen and (min-width: 1068px) {
    .form-group .toggle + label > div {
        width: 80vw;
        max-width: 80vw;
    }

    .error-page .logo {
        padding: 0.1em 1em 1em 1em;
    }
}

@media screen and (min-width: 1288px) {
    .form-group .toggle + label > div {
        width: 70vw;
        max-width: 70vw;
    }

    .error-page .logo {
        padding: 0.1em 1em 1em 1em;
    }
}

@media screen and (min-width: 1500px) {
    .form-group .toggle + label > div {
        width: 60vw;
        max-width: 60vw;
    }

    .error-page .logo {
        padding: 0.1em 1em 1em 1em;
    }
}

@media screen and (min-width: 1500px) {
    .form-group .toggle + label > div {
        width: 55vw;
        max-width: 55vw;
    }

    .error-page .logo {
        padding: 0.1em 1em 1em 1em;
    }
}

@media screen and (min-width: 1900px) {
    .form-group .toggle + label > div {
        width: 50vw;
        max-width: 50vw;
    }

    .error-page .logo {
        padding: 0.1em 1em 1em 1em;
    }
}

.form-group .toggle + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2em;
    height: 2em;
    background-color: var(--colour-white);
    border-radius: 9999px;
    transition: var(--timing-animation);
}

@media (prefers-reduced-motion) {
    .form-group .toggle + label:after {
        transition: none;
    }
}

.form-group .toggle:checked + label {
    background-color: var(--colour-primary);
}

.form-group .toggle:checked + label:after {
    left: 100%;
    transform: translateX(-100%);
}

.form-group .toggle:disabled + label:after {
    opacity: 0.5;
}

.form-group .tip {
    margin: -1em 0 1em 0;
    font-size: 0.8em;
}

.form-group .form-label-file + .tip,
.form-group .form-file-filename + .tip {
    margin-top: 2em;
}

.form-group .form-label-checkbox + .tip,
.form-group .form-label-radio + .tip,
.form-group .tox-tinymce + .tip {
    margin-top: 1em;
}

.form-file {
    margin-top: 3em;
}

.form-file-filename {
    display: inline;
    position: relative;
    padding: 0.2em 1em;
    margin-left: 2em;
    border: solid 1px var(--colour-dark-grey);
}

/** Components - Lists and collections */

.card-list {
    justify-content: flex-start;
    align-items: stretch;
}

.card-list .card {
    justify-content: space-between;
    border-color: var(--colour-dark-grey);
    box-shadow: 4px 4px 4px 0 var(--colour-dark-grey);
}

.card-list .card p:first-of-type {
    margin-top: 0;
}

.card-list .card h2 + p:first-of-type,
.card-list .card h3 + p:first-of-type,
.card-list .card h4 + p:first-of-type,
.card-list .card h5 + p:first-of-type {
    margin-top: 1rem;
}

.card-list .card p:last-of-type {
    margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
    .card-list .card {
        flex: auto;
        width: calc(33.333333% - 3rem);
        max-width: calc(33.333333% - 3rem);
    }
}

/** Components - Navigation */

aside {
    min-width: 200px;
    border-right: solid var(--border-width) var(--colour-primary);
    padding: 7em 1em 0 0;
    margin-right: 1em;
}

aside .navigation > ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

aside .navigation > ul li {
    padding: 0;
}

aside .navigation > ul li.break {
    display: none;
}

aside .navigation > ul li:not(.break) + li.break {
    display: list-item;
    padding: 1em 0 0 0;
    margin-bottom: 1em;
}

aside .navigation > ul li a:not(.button) {
    display: inline-block;
    padding: 0.5em 0;
    border-bottom: solid 2px transparent;
    color: var(--colour-black);
}

aside .navigation > ul li.active > a,
aside .navigation > ul li > a:active,
aside .navigation > ul li > a:hover {
    color: var(--colour-primary);
    font-weight: bold;
}

aside .navigation a {
    padding-right: 10px;
}

aside .navigation svg {
    min-width: 20px;
    margin: 0 5px 0 5px;
}

aside .navigation > ul li > ul li {
    margin: 0;
}

aside .navigation > ul li > ul li:last-of-type {
    margin-bottom: 0;
}

aside .navigation > ul li > ul li a {
    border: none;
}

.left-align-model-component > div {
    padding: 0.3rem !important;
    align-items: start;
    //flex-direction: column-reverse;
}

.left-align-model-component > div > a {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
}

.left-align-model-component > div.active-image-button {
    border: 2px solid #6b7280;
    border-radius: var(--border-radius);
}

.left-align-model-component > div:hover {
    border: 2px solid #6b7280;
    border-radius: var(--border-radius);
}

.left-align-header-text {
    align-items: start !important;
}

.left-align-sub-header-text {
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.5em;
    text-align: left;
}

.no-button-color-model-component > div > a {
    background-color: transparent !important;
    color: #000000 !important;
    padding-left: 0 !important;
}

.no-button-color-model-component > div > a:hover {
    text-decoration: underline;
}

.inverted-two-column {
    color: #ffffff;
    background-color: #000000;
}
